/* Overlay styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .popup-form-group{
    background-color:#F4F4F7 ;
    padding: 16px;
    border-radius: 20px;
    margin-bottom: 16px;
  }
  .text-muted {
    font-family: 'Rubik', sans-serif; /* Use the Rubik font family */
    font-size: 14px; /* Set the font size to 14px */
    font-weight: 400; /* Set the font weight to 400 (normal weight) */
    color: #81898D !important;
    padding-left: 4px;
  }
  /* Popup content styling */
  .popup-content {
    background: #fff;
    padding: 32px;
    width: 650px;
    overflow: auto;
    max-width: 90%;
    border-radius: 30px;
    box-shadow: 0px 2px 10px 0px #00354F1A;
  }
  .popup-content .form-control, .popup-content .form-select, .popup-content .datetime .MuiInputBase-root{
    height: 44px;
    border: 1px solid #B3C2CA;
  }
  .popup-form-inputs {
    display: flex;
    gap: 24px;
  }
  .popup-form-inputs >div{
    width: 48%;
  }
  .popup-content h2 {
    margin-bottom: 0px;
  }
  .header-popup {
    display: flex;
    align-items: flex-center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .popup-content h4 {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
    text-align: left;
  }
  
  /* Form field styling */
  .popup-content form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  /* Button section styling */
  .popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  button.button.cancel{
    background-color: #fff;
    color: #E37461;
    text-decoration: underline;
  }
  .popup-buttons button {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    width: max-content;
    height: 53px;
    margin: 0;
  }
  
  .popup-buttons button[type="submit"] {
    background-color: #E37461;
    color: white;
    padding: 16px 20px;
  }
  
  .popup-buttons button[type="button"] {
    background-color: #ccc;
    color: #333;
  }
  