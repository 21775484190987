.logolayout {
  overflow: auto;
  background-color: var(--blue600);
}
.logolayout .formsection{
  height: 100vh;
  width: 50vw;
  z-index: 3;
  background-color: var(--light);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0px;
}
.logolayout .formsection > div {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 120px;
  padding-right: 190px;
}
.logolayout .logotitle {
  color: var(--light);
  margin-top: 4px;
  margin-left: 6px;
}
.logolayout .bottomlink {
  margin-top: 20px;
  display: flex;
  align-self: center;
  
}
.logolayout .bottomlink .currentR {
  text-align: center;
}
.logolayout .loginlink {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.logolayout .btn-tertiary {
  margin-left: 10px;
  font-size: 18px;
  text-align: center;
  line-height: 21.6px;
  margin-top: 5px;
}

.logolayout .topelement {
  position: fixed;
  float: right;
  height: 100%;
  top: 0px;
  left: 0px;
}
.logolayout .bottomelement {
  position: fixed;
  bottom: -17%;
  left: 26%;
  width: 100%;
  overflow: hidden;
}
.logolayout .logo {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.logolayout .im2 {
  width: 34.5%;
  min-width: 40px;
}
.logolayout .im1 {
  width: 34.5%;
  min-width: 40px;
}
.logolayout .section {
  height: 100vh;
}
.logolayout .title {
  margin-top: 38px;
}
.logolayout .backcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logolayout .backimg {
  margin-top: -8px;
}
.logolayout .backtext {
  margin-left: 15px;
  font-size: 22px;
}

.logolayout .successContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  z-index: 1000;

  
}
.logolayout .section2{
 z-index:10000;
 display:flex;
 flex-direction:column;
 flex-grow:1
}
.logolayout .successimg {
  width: 90px;
  height: 90px;
  stroke:#00354F
}
.logolayout .successTitle {
  margin-top: 50px;
}
.logolayout .successLink {
  width: 100%;
  margin-top: 40px;
}

.logolayout .bottom-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  margin-top: 24px;
  justify-content: flex-end;
}
.logolayout .labelimg{
  margin-right: 10px;
  margin-top: -4px;
}
 .subscription{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding:16px;
  margin-bottom:14px;
  border: 2px solid var(--blue400) ;
  border-radius: 14px;
  background-color:var(var(--light));
 
}
.logolayout .subscriptionfree{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding:16px;
  margin-bottom:24px;
  border-radius: 14px;
  margin-top: 4px;
  background-color: var(--blue600) ;
  font-size: 16px ;
  color: white;
}
.freetext{
  font-size: 16px;
}


.logolayout .subscriptionfree img{
  margin-right: 10px;
  margin-top: 2px;
  
}

 .subscription img{
  margin-right: 10px;
}
.logolayout .subscription .legend ,
.logolayout .currentR{
  margin-top: 5px;
}
 .subselected{
  border-color: var(--primary500);
  background-color:var(--primary300);
}
.logolayout .subtitle + .title{
  margin-top: 20px;
}
.logolayout .topelementsmall,
.logolayout .bottomelementsmall,
.logolayout .logosmall{
  display: none;
} 
.logolayout .subscriptionfree .imgblue{
  display: none;
}
.logolayout .plandesc{
  font-size: 14px !important;
 color: #04141C;
}
@media (max-width: 1200px) {
  .logolayout .formsection > div {
    padding-left: 40px;
    padding-right: 40px;
  }
 
}
@media (max-width: 768px) {
  .logolayout .leftbar{
    display: none;
  }
  .logolayout  .plandesc{
    font-size: 14px !important;
   color: white;
  }
  .logolayout .subselected  .plandesc{
    font-size: 14px !important;
   color: #04141C;
  }
  .logolayout  input:-webkit-autofill{
    -webkit-text-fill-color: var(--light) !important;
}
.logolayout .subscriptionfree .imgwhite{
  display: none;
}
.logolayout .subscriptionfree .imgblue{
  display:block;
}
.logolayout .subscriptionfree{
  background-color: white;
  color:var(--black600)
}
  .logolayout .formsection {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: var(--blue600);
    color: var(--light);
  }
  .logolayout .datetime .MuiInputBase-root{

    color:var(--light) ;
    background-color: rgb(25,72,95);
    border-color: var(--light);
 
  
  }
  
  .logolayout .successimg{
    stroke:var(--light) !important
  }
  .logolayout .backtext{
    color:var(--light)
  }
  .logolayout .formsection > div {
    padding-left: 30px !important;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
  }
  .logolayout .topelementsmall{
    display: block;
    position: fixed !important;
    top: 0px;
    left: 0px;

   }
   .logolayout .logosmall{
    display: flex;
    position: relative;
    margin-top: 32px;
    margin-bottom: 20px;
    justify-content: center;
   }
   .logolayout h1{
    font-size: 30px !important;
    text-align: center;
   }
   .logolayout  .logosmall img{
    width: 90px;
   }
   .logolayout .bottomelementsmall{
    display: block;
    position: fixed !important;
    bottom: -60px;
    right:-40px
    
   }
   .logolayout .im1{
     width: 300px;
     height: auto;
   }
   .logolayout .im2{
    width: 300px;
    height: auto;
   }
   .logolayout .subselected{
    color: var(--black600);
   }
  .logolayout  .form-control,
  .logolayout .form-select,
  .logolayout .inputbtn,
  .logolayout .input-group-icon ,
  .logolayout .dropdown-item:focus,
  .logolayout .dropdown-item:hover {
    background-color:  rgb(25,72,95) ;
    color:var(--light) ;
    font-size: 14px;
  }
  .logolayout  .dropdown-item.active{
    background-color:  rgb(25,72,95);
  }
 
  .logolayout  .iconBtn{
    background-color: transparent;
    border-width: 0px;
  }
  .logolayout  .form-label,
  .logolayout .dropdown-item,
  .logolayout .dropdown-input-title{
    color: var(--light);
  }
  .logolayout .form-control::placeholder,
  .logolayout .dropdown-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  .logolayout .dropdown-menu,
  .logolayout  .form-control,
  .logolayout .form-select{
    background-color:   rgb(25,72,95) ;
  }
}