.datetime{
  width: 100%;
 
}
.datetime .MuiFormControl-root{
  width: 100% !important;
}
.datetime .MuiInputBase-root{
  border-radius: 30px;
  border-width: 1px;
  background-color: var(--light);
  border-color: var(--blue400);
  height: 44px;
  font-size: 14px;

}
.datetime .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--blue400);
  font-size: 0px;
}
.datetime .dateerror > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: red;
}
.datetime .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
border-width: 0.5px;
  border-color: var(--primary500) ;
}
.datetime .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--primary500);
}
.datetime .MuiSvgIcon-root{
  color:var(--primary500)
}