.welcome{

}
.welcome .lastclients{
margin-top: 5px;

}
.welcome .lastclients >div{
    padding: 0px;
}
.welcome .tariflogo{
    width: 115px;
     height: 154px;
      margin-top: 27px;
}
.welcome .tarifcontainer{
  margin-left: 20px;
}
.popuptarif .form-label{
  font-size: 18px;
}
.popuptarif .dropdown-input-placeholder,
.popuptarif .form-control,
.popuptarif .form-select,
.popuptarif .dropdown-input-title {
  font-size: 16px;
}
.welcome .tarifs{
    background-color: #FCF1EF;
    border-radius: 20px;
    padding: 24px;
    border-width: 1.2px;
    border-color: #EEACA0;
    border-style: solid;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width: 411px;
}
 @media (max-width: 1190px) {
  .welcome .tarifcontainer {
      margin-left: 0px;
    }
    .welcome .tarifs,
    .welcome .tarifcontainer{
      width: 100% !important;
    }
   
  } 