.structurelayout {
  padding: 0px;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--blue600);
}

.structurelayout .header {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 15px;
  z-index: 2;
  color: var(--light);
}
.structurelayout .navbar {
  padding: 0px;
  padding-right:16px;
  padding-left: 40px;
  padding-top: 5px;
  color: white;
  z-index: 2;
}
.structurelayout .nav {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: 5px;
}
.structurelayout .navbar {
  display: flex;
  flex-grow: 1;
}
.structurelayout .backimg {
  position: absolute;
  top: 0px;
  left: -5px;
  width: 635px;
  height: 656px;
  z-index: 1;
}
.structurelayout .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 26px;
  padding-bottom: 24px;
  align-items: center;
  color: white;
  overflow: auto;
  z-index: 2;
}
.structurelayout .headertitle {
  margin-top: 20px;
}
.structurelayout .link {
  color: var(--light);
  font-size: 16px;
  font-family: Rubik-Medium;
  line-height: 19.2px;
}
.structurelayout .link-active {
  color: var(--primary500);
  font-size: 16px;
}
.structurelayout .link1 {
  color: var(--light);
  font-size: 24px;
  font-family: Rubik;
  padding: 10px 24px 10px 24px;
}
.structurelayout .profilheader {
  margin-top: 37px;
  padding-bottom: 15px;
}
.structurelayout .link1-active {
  color: var(--light);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  font-size: 24px;
  font-family: Rubik-Medium;
  padding: 10px 24px 10px 24px;
}
.structurelayout .linkimg {
  margin-right: 10px;
  width: 16px;
}

.structurelayout .mt-20 {
  margin-top: 20px;
}
.structurelayout .mt-40 {
  margin-top: 40px;
}
.structurelayout{
  scrollbar-color:#00354f white;
  scrollbar-width: 0px !important;
}
.structurelayout ::-webkit-scrollbar {
  background-color: #00354f  !important;
  width: 7px;
  height: 7px;
}
.structurelayout ::-webkit-scrollbar-thumb {
  background-color: #00354f !important;
  display: none;
}

.structurelayout .content {
  background-color: var(--light);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom:30px;
  z-index: 2;
}
.structurelayout .content>div{
  padding-left: 80px;
  padding-right: 80px;
}
.structurelayout .toggle {
  width: 24px;
  margin-right: 20px;
  display: none;
}
.structurelayout .offcanvas-body {
  padding: 0px;
}
.structurelayout .sidebar {
  overflow: auto;
  display: none;
  height: 100vh;
  width: 300px;
  background-color: var(--blue600);
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.structurelayout .graycard{
  background-color: #F4F4F7;
  border-radius: 20px;
  border-width: 0px;
  padding: 24px;
}
.structurelayout h3{
  margin: 0px;
}
.structurelayout  .graycard .form-control,
.structurelayout  .graycard .form-select,
.structurelayout  .graycard .input-group-icon{
  border-color: #668695;
  height: 42px;
  max-width: 389px; 
}
.structurelayout  .datetime .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #668695;
}
.structurelayout  .ibanform .form-control,
.structurelayout  .ibanform .form-select,
.structurelayout .ibanform   .datetime .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ,
.structurelayout  .ibanform .input-group-icon{
  max-width: 100%; 
}
.structurelayout .ibaninput{
  max-width: 100%;
}
.structurelayout .cancelbtniban{
  width: 111px;
}
.structurelayout  .graycard .form-control:hover,
.structurelayout  .graycard  .form-select:hover,
.structurelayout  .graycard .form-control:hover ~ .input-group-icon {
  border-color: var(--primary400);
}
.structurelayout  .graycard .form-control:focus,
.structurelayout  .graycard .form-select:focus,
.structurelayout  .graycard .form-control:focus ~ .input-group-icon {
  border-color: var(--primary500);
  box-shadow: unset !important;
  outline: 0 none !important;
}
.structurelayout  .graycard .form-control-invalid,
.structurelayout  .graycard .form-select-invalid,
.structurelayout  .graycard .form-control-invalid ~ .input-group-icon {
  border-color: red;
}

.structurelayout .graycard  .form-text{
   color: var(--black600);
}

.structurelayout .sidebartop {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;
}
.structurelayout .sidebarbottom {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.structurelayout .offcanvas {
  display: none;
}
.flexEnd{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
   align-items: flex-start;
}
.headercontent{
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 80px;
  padding-right: 80px;
  color: white;
}

.structurelayout .btnpad40{
  padding-left: 40px;
  padding-right: 40px;
}
.btnenreg{
  margin-left: 15px;
}
@media (max-width: 280px) {
  .btnenreg{
    margin-left: 0px;
  }
  .cancelbtniban{
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .structurelayout .backimg {
    width: 300px;
  }
  .headercontent{
    padding-left: 0px;
    margin-top: 0px;
  }
  .structurelayout  .graycard .form-control,
  .structurelayout  .graycard .form-select,
  .structurelayout  .graycard .input-group-icon{
    max-width: 100%;
  }
 
  .structurelayout .btnpad40{
    padding-left: 10px;
    padding-right: 10px;
  }
  .structurelayout .navbar{
    padding-left: 0px;
    padding-right: 0px;
  }
  .structurelayout .header,
  .structurelayout .content >div,

  .structurelayout .headercontent {
    padding-left: 24px;
    padding-right: 24px;
  }
  .structurelayout .footer,
  .structurelayout .nav,
  .structurelayout .profilheader {
    display: none !important;
  }
  .structurelayout .offcanvas,
  .structurelayout .toggle {
    display: inline-block !important;
  }
  .structurelayout .headertitle {
    font-size: 30px;
  }

  .structurelayout h3{
   font-size: 20px
  }
  .structurelayout h2{
    font-size: 24px
   }
   .structurelayout h1{
    font-size: 30px
   }
   .structurelayout .popup-content {
    max-height: 90vh;
    overflow: auto;
   }
   .structurelayout .currentM{
      font-size: 16px;
   }
   .structurelayout .form-check{
    display: flex;
    flex-direction: row-reverse;
   
 }
 .structurelayout .form-check-label {
  font-size: 16px !important;
 }
   .structurelayout .currentR{
    font-size: 16px;
 }
   .structurelayout .profile-picture{
      width: 100px !important;
      height: 100px !important;
   }

}
@media (max-width: 800px) {
  .structurelayout  .link,
  .structurelayout .link-active {
    font-size: 15px !important;
  }
  .structurelayout .link1,
  .structurelayout .link1-active {
    font-size: 17px !important;
  }
}
