.form-check-input ,.form-check-input:focus{
  border-color: var(--primary500);
  outline: 0;
  box-shadow: 0 0 0 0 var(--primary500);
  width: 24px;
  height: 24px;
  border-radius: 0px !important;
}
.form-check-input:checked{
  background-color: var(--primary500);
  border-color: var(--primary500);
  box-shadow: 0 0 0 0 var(--primary500);
  box-shadow: unset !important;
  order: none;
  border-radius: 0px !important;
}
