/* ClientHeader.css */
.back-link svg{
  margin-right: 8px;
}
.back-link{
  font-size: 18px;
}

.client-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 16px;
    padding-bottom: 24px;
    border-radius: 8px;
  }
  
  .profile-picture {
    width: 161px;
    height: 161px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .client-info {
    flex: 1;
  }
  
  .client-info h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .client-info p {
    margin: 0.5rem 0 0;
    color: #FFF;
  }
  
  .actions button {
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    color: #fff;
    background-color: #FFFFFF1A;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 30px;
  }
  
  .actions button:last-child {
    background-color: #FFFFFF1A;
  }
  