.page404 {
  height:100%;

}
.page404 .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.page404 .error {
  text-align: center;
  background-color: #fff;
  padding: 80px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.page404 .error h1 {
  margin: 0;
  font-size: 130px;
  margin-bottom: 50px;
  color: var(--primary500);
}

.page404 .error p {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #555;
}

.page404 .error a {
  text-decoration: none;
  color: #fff;
  background-color: var(--primary500);
  padding: 15px 40px;
  border-radius: 30px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.page404 .error a:hover {
  background-color: var(--primary500);
}

@media (max-width: 500px) {
  .page404 .error {
    padding: 20px;
  }

  .page404 .error a {
    padding: 12px 30px;
  }
  .page404 .error h1 {
    font-size: 60px;
    margin-bottom: 20px;
  }
  .page404 .error p {
    font-size: 24px;
  }
}