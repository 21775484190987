.itemslist {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}
.itemslist > div {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.itemslist .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.itemslist .head h3 {
  margin-right: 13px;
}

.itemslist .listcol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemslist .emptydata {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.itemslist .list-group {
  flex: 1;
  margin-top: 14px;
  width: 100%;
  min-width: 600px;
}
.itemslist .cellspan {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemslist .list-group-item {
  padding: 8px 16px 8px 16px;
  border-width: 0px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
}
.itemslist .divider {
  height: 15px;
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  align-self: center;
  background-color:#B3C2CA ;
}
