
.structurelayoutLP .header-container {
  display: flex;
}
.structurelayoutLP .header-left {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
}
.structurelayoutLP .header-right {
  display: flex;
  position: relative;
  width: 50%;
}
.structurelayoutLP .header {
  display: flex;
  align-items: center;
  padding-left: 80px;
  padding-top: 24px;
  z-index: 100;
  position: relative;
}
.structurelayoutLP .header-nav {
  display: flex;
  gap: 20px;
  padding-left: 75px;
}
.structurelayoutLP .header-nav .nav-link-custom {
  text-decoration: none;
  color: var(--blue600);
  font-family: Rubik;
  font-size: 16px;
  line-height: 18.96px;
}
.structurelayoutLP .mr-75 {
  margin-right: 75px;
}
.structurelayoutLP .nav-link-custom {
  text-decoration: none !important;
  color: var(--blue600) !important;
  font-family: Rubik !important;
  font-size: 16px !important;
  line-height: 18.96px;
  font-weight: 400 !important;
  padding-right: 40px !important;
}
.structurelayoutLP .header-content {
  /* padding-top: 155px; */
  z-index: 100;
  margin: auto;
}
.structurelayoutLP .name {
  color: var(--blue500);
}
.structurelayoutLP .headertitle {
  color: var(--blue600);
}
.structurelayoutLP .pl-80 {
  padding-left: 80px;
}
.structurelayoutLP .pb-204 {
  padding-bottom: 204px;
}
.structurelayoutLP .pb-32 {
  padding-bottom: 32px;
}
.structurelayoutLP .pt-16 {
  padding-top: 16px;
}
.structurelayoutLP .bg-bleu {
  background-color: var(--blue600);
}
.structurelayoutLP .bg-light {
  background-color: var(--light) !important;
  padding-top: 40px;
  padding-bottom: 24px;
}
.structurelayoutLP .bg-light100 {
  background-color: var(--light100);
}
.structurelayoutLP .discover-title {
  padding-top: 96px;
  padding-bottom: 65px;
  display: flex;
  justify-content: center;
}
.structurelayoutLP .discover-row {
  flex-direction: column;
  width: 80%;
}
.structurelayoutLP .discover-row-container {
  padding-bottom: 48px;
}
.structurelayoutLP .discover-row-container-bottom {
  padding-bottom: 96px;
}
.structurelayoutLP .disover-texts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 84px;
}
.structurelayoutLP .link {
  color: var(--light);
  font-size: 16px;
  font-family: Rubik-Medium;
  line-height: 19.2px;
}
.structurelayoutLP .link-active {
  color: var(--primary500);
  font-size: 18px;
}
.structurelayoutLP .text-primary-color {
  color: var(--blue600);
}
.structurelayoutLP .mr-16 {
  margin-right: 16px;
}
.structurelayoutLP .mt-20 {
  margin-top: 20px;
}
.structurelayoutLP .mt-40 {
  margin-top: 40px;
}
.structurelayoutLP .mt-32 {
  margin-top: 32px;
}
.structurelayoutLP .mb-40 {
  margin-bottom: 40px;
}
.structurelayoutLP .pb-40 {
  padding-bottom: 40px;
}
.structurelayoutLP .pb-104 {
  padding-bottom: 104px;
}
.structurelayoutLP .pb-105 {
  padding-bottom: 105px;
}
.structurelayoutLP .er {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.structurelayoutLP .mt-16 {
  margin-top: 16px;
}
.structurelayoutLP .mb-16 {
  margin-bottom: 16px;
}
.structurelayoutLP .mb-140 {
  padding-bottom: 140px;
}
.structurelayoutLP .footer {
  background-color: var(--blue600);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.structurelayoutLP .footer-logo img {
  width: 48.16px;
  height: 33.91px;
  margin-bottom: 40px;
  margin-top: 80px;
}
.structurelayoutLP .footer-links {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 24px;
}
.structurelayoutLP .footer-links .link {
  margin: 5px 0;
  text-decoration: none;
}
.structurelayoutLP .footer-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.structurelayoutLP .footer-icons img {
  /* width: 29.83px; */
  margin: 0 8px;
  z-index: 2;
}
.structurelayoutLP .offcanvas {
  display: none;
}
.structurelayoutLP .whitecard {
  background-color: var(--light);
  border-radius: 30px;
  border-width: 0px;
  padding: 24px;
  min-height: 600px;
}
.structurelayoutLP .discover-advantages {
  display: flex;
  justify-content: center;
  padding-top: 104px;
  padding-bottom: 24px;
}
.structurelayoutLP .discover-tarification {
  display: flex;
  justify-content: center;
  padding-top: 104px;
  padding-bottom: 48px;
}
.structurelayoutLP .discover-advantages-paragraph {
  display: flex;
  justify-content: center;
  padding-bottom: 58px;
}
.structurelayoutLP .description {
  color: var(--blue600);
  /* padding-bottom: 40px;
  padding-right: 32px; */
}
.structurelayoutLP .header-left-container {
  padding: 10%;
  margin-top: 75px;
}
.structurelayoutLP .link1 {
  color: var(--light);
  font-size: 24px;
  font-family: Rubik;
  padding: 10px 24px 10px 24px;
}
.structurelayoutLP .link1-active {
  color: var(--light);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  font-size: 24px;
  font-family: Rubik-Medium;
  padding: 10px 24px 10px 24px;
}
.structurelayoutLP .content {
  /* overflow: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column; */
  background-color: var(--light);
  /* padding: 30px; */
  /* padding-left: 50px;
  padding-right: 50px;
  z-index: 2; */
}
/* .horse-img {
  width: 110%;
  flex: 1;
  object-fit: cover;
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  height: 100%;
} */
.structurelayoutLP .horse-img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 100;
}
.structurelayoutLP .ph-20{
  margin-left: 20px;
  margin-bottom: 20px;
}
.structurelayoutLP .ele {
  z-index: 100;
  position: relative;
}
.structurelayoutLP .ele3 {
  position: absolute;
  /* top: -150; */
  left: 0;
  z-index: 900;
}
.structurelayoutLP .ele4 {
  position: absolute;
  bottom: -200;
  right: 0;
  z-index: 100;
}
@media (max-width: 425px) {
  .structurelayoutLP .header {
    width: 100%;
    flex: 1;
    padding-left: 10px;
  }
  .structurelayoutLP .navbar {
    width: 100%;
  }
  .structurelayoutLP .ele3 {
    display: none;
  }
  .structurelayoutLP .ele4 {
    display: none;
  }
  .structurelayoutLP .footer-links {
    justify-content: center;
    text-align: center;
  }
  .structurelayoutLP .me-5 {
    margin-right: 1em !important;
  }
  .structurelayoutLP .discover-advantages {
    text-align: center;
  }
  .structurelayoutLP .nav-link-custom {
    text-decoration: none;
    color: var(--blue600) !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    line-height: 18.96px;
    font-weight: 400 !important;
    padding-right: 40px !important;
  }
}

@media (max-width: 1050px) {
  .structurelayoutLP .header-right {
    display: none;
  }
  .structurelayoutLP .header-left {
    width: 100%;
  }
  .structurelayoutLP .header {
    width: 100%;
  }
  .structurelayoutLP .header-content {
    padding: 10;
    margin-right: 80px;
  }
  .structurelayoutLP .nav-link-custom {
    text-decoration: none;
    color: var(--blue600) !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    line-height: 18.96px;
    font-weight: 400 !important;
    padding-right: 40px !important;
  }
}
@media  (max-width: 768px) {
  .structurelayoutLP .discover-row{
  width: 100%;
}
.structurelayoutLP .footer-links{
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: center;
}
}
/* @media (max-width: 636px) {
  .header,
  .content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .footer,
  .nav,
  .profilheader {
    display: none;
  }
  .offcanvas,
  .toggle {
    display: inline-block;
  }
  .headertitle {
    font-size: 30px;
  }
} */
/* @media (max-width: 800px) {
  .link,
  .link-active {
    font-size: 15px !important;
  }
  .link1,
  .link1-active {
    font-size: 17px !important;
  }
} */