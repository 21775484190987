.add-client-form .bg-grey{
    background-color: #F4F4F7;
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 32px;
}

.add-client-form  .btn-addcl{
    background-color: #81898D;
    color: #fff;
    border: 1px solid#81898D;
}
.add-client-form  .bg-grey h2{
    font-size: 28px;
    color: #04141C;
    margin-bottom: 16px;
}
.add-client-form  .margin-24{
    margin-bottom: 24px;
}
.add-client-form .btn-addcl{
    width: auto;
    padding: 16px 20px;
    margin-right: 0;
    margin-left: auto;
}
.add-client-form  label.form-check-label{
    font-size: 18px;
    font-weight: 400;
    font-family: Rubik;
}
.add-client-form  input.form-check-input{
    width: 24px;
    height: 24px;
    border-radius: 30px !important;
    margin-right: 8px;
    margin-top: -1px;
}
.add-client-form  .input-group{
    height: 90px;
  
    border: 1px solid #B3C2CA;
    border-radius: 15px;
    border-style: dotted;
    display: flex;
    
    align-items: center;
    justify-content: center;
}
.add-client-form  button.btn.btn-primary.file-input-button{
    background-color: #fff;
    font-size: 18px;
    color: #E37461;
}
.add-client-form  .form-control[type=file]{
    
    width: 100%;
    border: none;
}