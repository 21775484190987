/* Non-active button styles */
.btn-non-active {
    border: 1px solid #EEACA0; 
    background: rgba(255, 255, 255, 0.2); 
    color: #fff; 
  }
  

  
  /* Remove focus outline from primary button */
  .btn-primary:focus {
    outline: none; 
  }
  
  /* Ensure buttons are side by side */
  .tab-buttons {
    display: flex; 
    gap: 16px; 
    width: fit-content;
    padding-top: 32px;
  }