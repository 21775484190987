/* ClientInfo.css */


.client-info-container {
    background-color: #F4F4F7;
    padding: 24px;
    border-radius: 20px;
    margin-bottom: 24px;
  }
  
  .client-info-row {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .client-info-row div {
    flex: 1;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .client-info-container h2 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #04141C;
    line-height: 30px;
  }
  .client-info-row h3 {
    font-size: 14px;
    margin-bottom: 4px;
    color: #04141C;
  }
  
  .client-info-row p {
    font-size: 18px;
    color: #04141C;
    margin-bottom: 16px;
  }
  