.filtermodal{
    border-radius: 30px;
    min-width: 411px;
    min-height: 382px;
    overflow: hidden;
    max-width: 519px;
    padding: 32px;
    padding-bottom: 15px;
    
}
.filtermodal .modalcontent{
    height: 90%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}
.filtermodal .btn-primary{
    width: 211px !important;
}
.filtermodal .btn-tertiary{
   
}

.filtermodal .modaltext{

      flex-grow: 1; display: flex; align-items: center; flex-direction: column;
}
.filtermodal .modaldesc{
    margin-top: 10px;
    text-align: center;
}
.filtermodal .modaltitle{
 
    text-align: center;
}

@media (max-width: 385px) {
.filtermodal{
    width: 92vw !important;
    min-width: 90dvw !important;
    overflow: hidden;
    
}
.react-responsive-modal-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

}